import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Prestashop",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/prestashop.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Prestashop`}</h1>
    <p>{`Easy-to-install Live Chat addons for Prestashop`}</p>
    <hr></hr>
    <p>{`Prestashop is an open-source and free to use platform to build your own E-commerce website. Looking for and easy-to-install Live Chat addons for your Prestashop ? Using this integration, add the Crisp Live chat in a single click on your Prestashop website!`}</p>
    <p>{`Benefits of using our Live chat addons for Prestashop:`}</p>
    <ul>
      <li parentName="ul">{`Add the Crisp Livechat in a single click`}</li>
      <li parentName="ul">{`Manage all your conversations from the Crisp Inbox`}</li>
      <li parentName="ul">{`Automatically push user data when someone is already logged-in`}</li>
      <li parentName="ul">{`Automatically adapts to your website language (50+ locales are supported)`}</li>
      <li parentName="ul">{`Boost users engagement thanks to Live Chat`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
      <li parentName="ul">{`Limite checkout abandon rate with instant messages`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      